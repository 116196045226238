import {
  WppActionButton,
  WppButton,
  WppIconArrow,
  WppTooltip,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { HierarchyNode } from '@wpp-open/core/types/mapping/common'
import { useOs } from '@wpp-open/react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { CreateProjectAttribute, CreateProjectDTO } from 'api/projects/fetchers/createProjectApi'
import { useCreateProjectApi } from 'api/projects/mutation/useCreateProjectApi'
import { useFetchWorkflowTemplateByIdApi } from 'api/templates/queries/useFetchWorkflowTemplateByIdApi'
import { useFetchNavigationNodeParents } from 'api/tenant/queries/useFetchNavigationNodeParents'
import { Flex } from 'components/common/flex/Flex'
import { SideModalContentSkeleton } from 'components/common/sideModalSkeleton/SideModalContentSkeleton'
import { tableActions } from 'components/common/table'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { ACTION_ANALYTICS } from 'constants/analytics'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { TableKey } from 'constants/table'
import { useForm } from 'hooks/form/useForm'
import { useTrackAction } from 'hooks/useAnalytics'
import { useCanCreateProject } from 'hooks/useCanCreateProject'
import { useHierarchy } from 'hooks/useHierarchy'
import { useMiroConnected } from 'hooks/useMiroConnected'
import { useTimeTracker } from 'hooks/useTimeTracker'
import { useToast } from 'hooks/useToast'
import { useWrike } from 'hooks/useWrike'
import { Integrations } from 'pages/components/projectModal/components/integrations/Integrations'
import { MembersGroups } from 'pages/components/projectModal/components/membersGroups/MembersGroups'
import { GroupAssignment } from 'pages/components/projectModal/components/membersGroups/utils'
import { MembersRoles } from 'pages/components/projectModal/components/membersRoles/MembersRoles'
import { ProjectForm } from 'pages/components/projectModal/components/projectForm/ProjectForm'
import { ProjectTemplateForm } from 'pages/components/projectModal/components/projectTemplateForm/ProjectTemplateForm'
import styles from 'pages/components/projectModal/CreateProjectModal.module.scss'
import {
  fixDefaultValueForForm,
  getAttributesDefaults,
  normalizeProjectFormDate,
  useCreateProjectTemplatesCount,
  useHierarchyDefaults,
} from 'pages/components/projectModal/utils'
import { withAttributes } from 'pages/components/projectModal/withAttributes'
import { TemplateCard } from 'pages/components/templateCard/TemplateCard'
import { modalValidationScheme } from 'pages/dashboard/components/utils'
import { queryClient } from 'providers/osQueryClient/utils'
import { ProjectAttribute, ProjectAttributeClass } from 'types/projects/attributes'
import { ContextHierarchy, ProcessType, ProjectStatus, ProjectType } from 'types/projects/projects'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'
import { CustomError, is409Error, is422Error } from 'utils/error'
import { routesManager } from 'utils/routesManager'

interface Props extends NiceModalWrappedProps {
  templatePresetId?: string
  attributes?: ProjectAttribute[]
}

enum CreateStep {
  DETAILS = 'details',
  TEMPLATE_FORM = 'templates',
  MEMBERS_GROUPS = 'members_groups',
  MEMBERS_ROLES = 'members_roles',
  INTEGRATIONS = 'integrations',
}

const defaultValues = {
  name: '',
  type: ProjectType.CAMPAIGN,
  description: '',
  processType: ProcessType.LINEAR,
  dueDate: [],
  templateId: '',
  wrikeProjectId: '',
  wrikeConnectionToggle: false,
  groupAssignments: [] as MayBeNull<GroupAssignment>[],
}

export type CreateProjectFormType = typeof defaultValues

const CreateProjectModal = ({ isOpen, onClose, onCloseComplete, templatePresetId, attributes = [] }: Props) => {
  const { t } = useTranslation()
  const { showToast } = useToast()
  const {
    osContext: { tenant, userDetails },
  } = useOs()

  const { calculateTimeDifferenceInSeconds } = useTimeTracker()

  const navigate = useNavigate()
  const [currentStepIdx, setCurrentStepIdx] = useState(0)
  const { mutateAsync: createProject } = useCreateProjectApi()
  const [wrikeError, setWrikeError] = useState(null)

  const { trackAction } = useTrackAction()

  const { canCreateProjectFromScratch } = useCanCreateProject()
  const { hierarchyOrder } = useHierarchy()
  const { integrationAvailable, isIntegrationsLoading } = useWrike()

  const systemAttributes = useMemo(
    () =>
      attributes
        ?.filter(attribute => attribute.classification === ProjectAttributeClass.SYSTEM)
        .map(fixDefaultValueForForm) || [],
    [attributes],
  )

  const customAttributes = useMemo(
    () =>
      attributes
        .filter(attribute => attribute.classification === ProjectAttributeClass.CUSTOM && attribute.enabled)
        .map(fixDefaultValueForForm)
        .sort((a, b) => a.orderNumber! - b.orderNumber!),
    [attributes],
  )

  const { systemDefaults, customDefaults } = useMemo(
    () => ({
      systemDefaults: getAttributesDefaults(systemAttributes || []),
      customDefaults: getAttributesDefaults(customAttributes || []),
    }),
    [customAttributes, systemAttributes],
  )

  const hierarchyDefaults = useHierarchyDefaults()

  const form = useForm({
    defaultValues: {
      ...defaultValues,
      // system attributes will be on the root level, custom - as a separate group
      ...systemDefaults,
      customAttributes: customDefaults,
      hierarchy: hierarchyDefaults,
    },
    validationSchema: modalValidationScheme(hierarchyOrder, [...systemAttributes, ...customAttributes]),
  })

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    getValues,
    setValue,
    trigger,
    watch,
  } = form

  const [processType, templateId, groupAssignments] = watch(['processType', 'templateId', 'groupAssignments'])
  const { isTemplatesCountLoading } = useCreateProjectTemplatesCount()

  // @TODO: re-factor v.novikov
  const getLastSelectedHierarchy = () => {
    const hierarchy = getValues('hierarchy')
    const selectedWorkspace = hierarchyOrder.map(order => hierarchy[order as keyof typeof hierarchy] || '') as string[]
    return [...selectedWorkspace].reverse().find(el => el !== '') || ''
  }

  const { data: template, isLoading: isTemplateLoading } = useFetchWorkflowTemplateByIdApi({
    params: { id: templateId! },
    enabled: !!templateId,
  })

  const { data: treeToMap } = useFetchNavigationNodeParents({
    params: {
      tenantId: tenant.id,
      nodeId: getLastSelectedHierarchy(),
    },
    enabled: !!getLastSelectedHierarchy(),
  })

  const hasMiroApps = !!template?.hasMiroApps
  const { isMiroConfigured, isMiroFetching, isMiroConnected, isMiroNotConnected } = useMiroConnected(hasMiroApps)

  useEffect(() => {
    if (!templatePresetId) return
    setValue('templateId', templatePresetId)
  }, [templatePresetId, setValue])

  useEffect(() => {
    if (isTemplateLoading || !template) return
    setValue('processType', template.processType)
  }, [template, isTemplateLoading, setValue])

  const onSubmit = handleSubmit(
    async () => {
      if (hasMiroApps && isMiroNotConnected) {
        console.warn('Project creation was canceled: selected template requires Miro connection')
        return
      }

      await handleSave()
    },
    errors => console.error('Form is not valid', errors),
  )

  const handleSave = useCallback(async () => {
    setWrikeError(null)
    const {
      templateId,
      wrikeProjectId,
      wrikeConnectionToggle,
      groupAssignments,
      hierarchy,
      customAttributes,
      ...systemAttributes
    } = getValues()

    const selectedWorkspace = hierarchyOrder.map(order => hierarchy[order as keyof typeof hierarchy] || '') as string[]
    const contextWorkspace = [...selectedWorkspace].reverse().find(el => el !== '') || ''

    const projectAttributes = (attributes || [])
      .map(attr => {
        if (attr.contractName === 'startDate_endDate') {
          const value = !!systemAttributes.dueDate.length
            ? Object.values(normalizeProjectFormDate(systemAttributes.dueDate)).join('_')
            : null
          return {
            id: attr.id,
            value,
          }
        }

        // CONTEXT_WORKSPACE : "context" can be changed to any other key, so this comment is needed to track the key across project
        if (attr.contractName === 'contextWorkspace') {
          return {
            id: attr.id,
            value: contextWorkspace,
          }
        }

        const formAttributes = { ...systemAttributes, ...customAttributes }
        if (attr.contractName in formAttributes) {
          return {
            id: attr.id,
            value: formAttributes[attr.contractName as keyof typeof formAttributes] || '',
          }
        }

        return null
      })
      .filter(Boolean)

    const contextHierarchy: ContextHierarchy[] = selectedWorkspace.map((id, index) => {
      const node = treeToMap?.mapping[id] as HierarchyNode
      return {
        title: hierarchyOrder[index],
        name: node?.name || null,
        value: node?.azId || null,
        alias: node?.alias || null,
        originalName: node?.originalName || node?.name || null,
      } as ContextHierarchy
    })

    const groupAssignmentsDTO = (groupAssignments.filter(Boolean) as GroupAssignment[]).map(
      ({ groupId, email, role }) => ({ groupId, email, role }),
    )

    try {
      let projectBody: CreateProjectDTO = {
        projectAttributes: projectAttributes as CreateProjectAttribute[],
        contextHierarchy,
        status: ProjectStatus.TO_DO,
        ...(!!templateId && { templateId }),
        ...(!!templateId && { groupAssignments: groupAssignmentsDTO }),
      }

      if (wrikeConnectionToggle) {
        projectBody = {
          ...projectBody,
          wrikeProjectId,
        }
      }

      const {
        data: { id },
      } = await createProject(projectBody)

      Promise.all([
        await queryClient.invalidateQueries([ApiQueryKeys.PROJECTS_FETCHER]),
        await queryClient.invalidateQueries([ApiQueryKeys.PROJECTS_INFINITE]),
        await tableActions.reload([TableKey.PROJECT_LIST]),
      ])

      const analyticsData = {
        name: systemAttributes.name.trim(),
        workspace_name: tenant.name,
        workspace_type: tenant.tenantType,
        user_agency: userDetails?.agency,
        project_hierarchy: contextHierarchy,
        project_type: systemAttributes.type,
        process_type: processType,
        is_created_from_template: !!templateId,
        connected_to_wrike: !!wrikeProjectId,
        duration_in_seconds: calculateTimeDifferenceInSeconds(),
      }

      trackAction(ACTION_ANALYTICS.ACTION_PROJECT_CREATE, analyticsData)

      navigate(routesManager.project.root.getURL({ id }))
      onCloseComplete()
    } catch (e: any) {
      const is422 = is422Error(e)
      const is409 = is409Error(e)

      if (e?.response?.data?.detail?.code?.includes('WRIKE_')) {
        setWrikeError(e)
        return
      }

      const CODE = (e as any).response?.data?.detail?.code

      let message = t('modals.create_project.toast_error_common')

      if (is422 && CODE === CustomError.TEMPLATE_NOT_FOUND) {
        message = t('modals.create_project.template_not_found')
        setValue('templateId', '')
        queryClient.invalidateQueries([ApiQueryKeys.WORKFLOW_TEMPLATES_INFINITE])
      }

      if (is409) message = t('modals.create_project.toast_error_duplicate')

      showToast({
        type: 'error',
        message,
      })
    }
  }, [
    getValues,
    hierarchyOrder,
    attributes,
    treeToMap?.mapping,
    createProject,
    tenant.name,
    tenant.tenantType,
    userDetails?.agency,
    processType,
    calculateTimeDifferenceInSeconds,
    trackAction,
    navigate,
    onCloseComplete,
    t,
    showToast,
    setValue,
  ])

  const groupAssignmentsLength = groupAssignments.filter(Boolean).length
  const availableSteps = useMemo(() => {
    const steps = [CreateStep.DETAILS]

    // prevent select templates when we have predefined one
    if (!templatePresetId) {
      steps.push(CreateStep.TEMPLATE_FORM)
    }

    if (template?.groups?.length) {
      steps.push(CreateStep.MEMBERS_GROUPS)

      if (groupAssignmentsLength) {
        // only if  we have selected members
        steps.push(CreateStep.MEMBERS_ROLES)
      }
    }

    if (integrationAvailable || hasMiroApps) {
      steps.push(CreateStep.INTEGRATIONS)
    }

    return steps
  }, [templatePresetId, template?.groups?.length, integrationAvailable, hasMiroApps, groupAssignmentsLength])

  // if user has no permission to create project from scratch, then he must select template
  const isTemplateMissed = canCreateProjectFromScratch
    ? false
    : !templateId && availableSteps[currentStepIdx] === CreateStep.TEMPLATE_FORM

  const isNextDisabled = isTemplateLoading || isMiroFetching || isTemplateMissed
  // we cannot create Project from Template with miro w/o miro config on tenant
  const isCreateDisabled = hasMiroApps && !isMiroConfigured

  const [logError, setLogError] = useState(false)
  const handleNextStep = async () => {
    const isValid = await trigger()

    if (isValid) {
      setCurrentStepIdx(currentStepIdx + 1)
    } else {
      setLogError(true)
    }
  }

  const handleBack = () => {
    setCurrentStepIdx(currentStepIdx - 1)
  }

  useEffect(() => {
    if (logError) {
      console.error('Form is not valid', errors)
      setLogError(false)
    }
  }, [errors, logError])

  const handleCancelEdit = () => {
    onClose()

    const analyticsData = {
      workspace_name: tenant.name,
      workspace_type: tenant.tenantType,
      user_agency: userDetails?.agency,
      duration_in_seconds: calculateTimeDifferenceInSeconds(),
    }

    trackAction(ACTION_ANALYTICS.ACTION_PROJECT_CREATE_CANCELLED, analyticsData)
  }

  return (
    <>
      <FormProvider {...form}>
        <SideModal
          open={isOpen}
          formConfig={{ onSubmit }}
          onWppSideModalClose={handleCancelEdit}
          onWppSideModalCloseComplete={onCloseComplete}
          size="m"
          data-testid="create-project-modal"
        >
          {isTemplatesCountLoading || isIntegrationsLoading ? (
            <SideModalContentSkeleton />
          ) : (
            <>
              <WppTypography slot="header" type="2xl-heading">
                {t('modals.create_project.title')}
              </WppTypography>

              <>
                <div slot="body" className={styles.modalBody}>
                  {availableSteps[currentStepIdx] === CreateStep.DETAILS && (
                    <>
                      <ProjectForm systemAttributes={systemAttributes} customAttributes={customAttributes} />
                      {templatePresetId && template && (
                        <Flex className={styles.presetContainer} direction="column" gap={12}>
                          <WppTypography slot="header" type="xl-heading">
                            {t('modals.create_project.selected_template')}
                          </WppTypography>
                          <TemplateCard className={styles.templatePreset} template={template} />
                        </Flex>
                      )}
                    </>
                  )}

                  {availableSteps[currentStepIdx] === CreateStep.TEMPLATE_FORM && (
                    <ProjectTemplateForm processType={processType} />
                  )}

                  {availableSteps[currentStepIdx] === CreateStep.MEMBERS_GROUPS && (
                    <MembersGroups template={template!} />
                  )}

                  {availableSteps[currentStepIdx] === CreateStep.MEMBERS_ROLES && <MembersRoles />}

                  {availableSteps[currentStepIdx] === CreateStep.INTEGRATIONS && (
                    <Integrations
                      showMiroConnect={hasMiroApps}
                      isMiroConnected={isMiroConnected}
                      miroNotConfigured={isCreateDisabled}
                      showWrikeConnect={integrationAvailable}
                      wrikeError={wrikeError}
                    />
                  )}
                </div>
                <Flex justify="between" slot="actions">
                  <Flex>
                    {availableSteps[currentStepIdx] !== CreateStep.DETAILS && (
                      <WppActionButton variant="primary" onClick={handleBack} data-testid="project-modal-back-button">
                        <WppIconArrow direction="left" className={styles.backIcon} />
                        {t('common.btn_back')}
                      </WppActionButton>
                    )}
                  </Flex>
                  <Flex gap={12}>
                    <WppButton variant="secondary" size="m" onClick={handleCancelEdit}>
                      {t('modals.create_project.btn_cancel')}
                    </WppButton>
                    {currentStepIdx + 1 < availableSteps.length ? (
                      <WppButton variant="primary" size="m" onClick={handleNextStep} disabled={isNextDisabled}>
                        {t('common.btn_next')}
                      </WppButton>
                    ) : (
                      <WppTooltip
                        header={t('integrations.miro.tenant_not_configured_title')!}
                        text={t('integrations.miro.tenant_not_configured_text')!}
                        config={{
                          trigger: !isCreateDisabled ? 'manual' : 'mouseenter',
                          hideOnClick: false,
                        }}
                      >
                        <WppButton
                          variant="primary"
                          size="m"
                          type="submit"
                          loading={isSubmitting}
                          disabled={isNextDisabled || isCreateDisabled}
                        >
                          {t('modals.create_project.btn_create')}
                        </WppButton>
                      </WppTooltip>
                    )}
                  </Flex>
                </Flex>

                <Flex justify="between" slot="actions" />
              </>
            </>
          )}
        </SideModal>
      </FormProvider>
    </>
  )
}

export const { showModal: showCreateProjectModal } = createNiceModal<Props>(
  withAttributes<Props>(CreateProjectModal),
  'create-project-modal',
)
